<template>
  <div v-if="profile">
    <b-row class="pb-4">
      <b-col md="5" sm="12">
        <div style="display: flex; flex-direction: row; align-items: center">
          <div>
            <h2 class="card-title m-0">
              <b-link :to="`/fleet/${id}${$route.query.redir ? `?redir=${$route.query.redir}` : ''}`" class="card-name mr-1">
                {{ profile.license_plate }}
              </b-link>
            </h2>
          </div>
          <div>
            <fleet-tags-group inline />
          </div>
        </div>
        <div class="small text-muted">
          <span @click="getActualThis">{{ $t('fleetProfile.texts.status') }}: </span>
          <b-badge :variant="setStatusBadge(profile.status)">
            {{ getStatusText(profile.status) }}
          </b-badge>
          <tickets-monitoring v-if="profile.license_plate !== 'Carregando...'" inline :license-plate="profile.license_plate" />
          <div>{{ getCarLabel() }}</div>
        </div>
      </b-col>
      <b-col md="7" sm="12">
        <b-row align-h="end">
          <b-button v-if="$route.name !== 'fleet-profile' && $route.query.redir" variant="secondary" class="mr-2"
                    @click="goBack"
          >
            <i class="fa fa-angle-left" />
            Voltar
          </b-button>
          <b-button v-if="profile.booking" class="mr-2" :to="`/drivers/profile/${profile.booking.driver.id}`">
            {{ $t('fleetProfile.buttons.driver') }}
          </b-button>
          <b-button class="mr-2" :to="'/fleet/' + id + '/edit' + ($route.query.redir ? '?redir=' + $route.query.redir : '')">
            {{ $t('fleetProfile.buttons.edit') }}
          </b-button>
          <fleet-group-buttons :id="id" :vin="vin" :is-rental="isRental" />
          <fleet-lock-unlock-button :id="id" :vin="vin" />
        </b-row>
      </b-col>
    </b-row>
    <b-row v-if="!isRental">
      <b-col>
        <b-alert show variant="danger">
          <strong>ATENÇÃO!</strong> Este é um veículo do Kovi Seguros
        </b-alert>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="12" sm="12" class="text-right fleet-nav">
        <b-nav tabs align="left">
          <b-nav-item :to="`/fleet/${id}`">
            {{ $t('fleetProfile.tabs.profile') }}
          </b-nav-item>
          <b-nav-item v-if="isRental" :to="`/fleet/${id}/bookings`">
            {{ $t('fleetProfile.tabs.leases') }}
          </b-nav-item>
          <b-nav-item :to="`/fleet/${id}/block-history`">
            {{ $t('fleetProfile.tabs.blockHistory') }}
          </b-nav-item>
          <b-nav-item v-if="isRental" :to="`/fleet/${id}/fines${$route.query.redir ? '?redir=' + $route.query.redir : ''}`">
            Multas
            <b-badge variant="light">
              {{ profile.total_fines }}
            </b-badge>
          </b-nav-item>
<!--          <b-nav-item v-if="isRental" :to="`/fleet/${id}/alerts`">-->
<!--            Alertas-->
<!--          </b-nav-item>-->
          <b-nav-item :to="`/fleet/${id}/tracker-v2`">
            {{ $t('fleetProfile.tabs.tracker') }}
          </b-nav-item>
          <b-nav-item :to="`/fleet/${id}/checklist`">
            {{ $t('fleetProfile.tabs.checklist') }}
          </b-nav-item>
          <b-nav-item :to="`/fleet/${id}/history`">
            Histórico (Beta)
          </b-nav-item>
          <!-- <b-nav-item :to="`/fleet/${id}/maintenance${$route.query.redir ? '?redir=' + $route.query.redir : ''}`">Manutenções</b-button> -->
        </b-nav>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { enumStatus, formatFuel, formatStatus } from '@utils/car';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import FleetGroupButtons from './fleet-group-buttons';
import FleetLockUnlockButton from './fleet-lock-unlock-button.vue';
import FleetTagsGroup from './fleet-tags-group.vue';
import TicketsMonitoring from './tickets-monitoring';

export default {
  name: 'FleetHeader',
  components: {
    TicketsMonitoring,
    FleetGroupButtons,
    FleetLockUnlockButton,
    FleetTagsGroup,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    car: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  data() {
    return {
      currentSession: null,
    };
  },
  computed: {
    ...mapGetters({
      profile: 'fleet/header',
    }),
    ...mapGetters('user', ['findGroups']),
    isRental: function () {
      return this.profile.product !== 'INSURANCE';
    },
    vin: function () {
      return this.profile.vin;
    },
  },
  mounted() {
    this.loadProfile();
  },
  beforeDestroy() {
    const isFleetProfilePath = this.$route.path.includes('/fleet/') && !!this.$route.params.id;
    if (!isFleetProfilePath) {
      this.clearHeader();
      this.clearVehicleMonitored();
    }
  },
  methods: {
    ...mapActions({
      clearHeader: 'fleet/clearHeader',
      setHeader: 'fleet/header',
    }),
    ...mapMutations('tickets', ['clearVehicleMonitored']),
    loadProfile() {
      this.clearHeader({ id: this.id });
      this.setHeader({ id: this.id });
    },
    getCarLabel() {
      if (this.profile.assemble_year) {
        return (
          this.profile.brand.name +
          ' ' +
          this.profile.model.name +
          ' ' +
          this.profile.assemble_year +
          '/' +
          this.profile.model_year +
          ' (' +
          this.setFuelFormat(this.profile.fuel) +
          ')'
        );
      } else {
        return '...';
      }
    },
    setStatusBadge(status) {
      return formatStatus(status);
    },
    setFuelFormat(fuel) {
      return formatFuel(fuel);
    },
    goBack() {
      if (this.$route.query.redir) {
        this.$router.push({
          path: this.$route.query.redir,
        });
      } else {
        this.$router.push({
          path: `/fleet/${this.id}`,
        });
      }
    },
    getActualThis() {},
    getStatusText(statusValue) {
      const statusInfo = enumStatus.find(status => status.value === statusValue);
      return statusInfo ? statusInfo.text.toUpperCase() : statusValue;
    },
  },
};
</script>

<style lang="scss" scoped>
.fleet-nav .nav-tabs {
  margin-bottom: 10px !important;
}
.fleet-nav .nav-tabs .nav-link {
  line-height: 18px;
}
.fleet-nav .nav-tabs .nav-link.active {
  background: rgb(255, 255, 255);
  background: linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
  border-bottom: 1px solid #f0f0f0;
}
.fleet-nav .nav-tabs .nav-link:hover {
  border-bottom: 1px solid #f0f0f0 !important;
}
.fleet-nav .nav-tabs.nav-item {
  margin-right: 4px;
  margin-bottom: -1px !important;
  line-height: 18px !important;
}
.fleet-nav .nav-tabs .dropdown-menu {
  box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.2);
}
.badge {
  top: -6px;
  font-weight: normal;
}
.card-title {
  .badge {
    font-size: 12px;
    font-weight: 200;
    vertical-align: top;
    margin-top: 2px;
  }
}
</style>
