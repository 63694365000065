var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.profile
    ? _c(
        "div",
        [
          _c(
            "b-row",
            { staticClass: "pb-4" },
            [
              _c("b-col", { attrs: { md: "5", sm: "12" } }, [
                _c(
                  "div",
                  {
                    staticStyle: {
                      display: "flex",
                      "flex-direction": "row",
                      "align-items": "center",
                    },
                  },
                  [
                    _c("div", [
                      _c(
                        "h2",
                        { staticClass: "card-title m-0" },
                        [
                          _c(
                            "b-link",
                            {
                              staticClass: "card-name mr-1",
                              attrs: {
                                to: `/fleet/${_vm.id}${
                                  _vm.$route.query.redir
                                    ? `?redir=${_vm.$route.query.redir}`
                                    : ""
                                }`,
                              },
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.profile.license_plate) + " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]),
                    _c(
                      "div",
                      [_c("fleet-tags-group", { attrs: { inline: "" } })],
                      1
                    ),
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "small text-muted" },
                  [
                    _c("span", { on: { click: _vm.getActualThis } }, [
                      _vm._v(
                        _vm._s(_vm.$t("fleetProfile.texts.status")) + ": "
                      ),
                    ]),
                    _c(
                      "b-badge",
                      {
                        attrs: {
                          variant: _vm.setStatusBadge(_vm.profile.status),
                        },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(_vm.getStatusText(_vm.profile.status)) +
                            " "
                        ),
                      ]
                    ),
                    _vm.profile.license_plate !== "Carregando..."
                      ? _c("tickets-monitoring", {
                          attrs: {
                            inline: "",
                            "license-plate": _vm.profile.license_plate,
                          },
                        })
                      : _vm._e(),
                    _c("div", [_vm._v(_vm._s(_vm.getCarLabel()))]),
                  ],
                  1
                ),
              ]),
              _c(
                "b-col",
                { attrs: { md: "7", sm: "12" } },
                [
                  _c(
                    "b-row",
                    { attrs: { "align-h": "end" } },
                    [
                      _vm.$route.name !== "fleet-profile" &&
                      _vm.$route.query.redir
                        ? _c(
                            "b-button",
                            {
                              staticClass: "mr-2",
                              attrs: { variant: "secondary" },
                              on: { click: _vm.goBack },
                            },
                            [
                              _c("i", { staticClass: "fa fa-angle-left" }),
                              _vm._v(" Voltar "),
                            ]
                          )
                        : _vm._e(),
                      _vm.profile.booking
                        ? _c(
                            "b-button",
                            {
                              staticClass: "mr-2",
                              attrs: {
                                to: `/drivers/profile/${_vm.profile.booking.driver.id}`,
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("fleetProfile.buttons.driver")
                                  ) +
                                  " "
                              ),
                            ]
                          )
                        : _vm._e(),
                      _c(
                        "b-button",
                        {
                          staticClass: "mr-2",
                          attrs: {
                            to:
                              "/fleet/" +
                              _vm.id +
                              "/edit" +
                              (_vm.$route.query.redir
                                ? "?redir=" + _vm.$route.query.redir
                                : ""),
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("fleetProfile.buttons.edit")) +
                              " "
                          ),
                        ]
                      ),
                      _c("fleet-group-buttons", {
                        attrs: {
                          id: _vm.id,
                          vin: _vm.vin,
                          "is-rental": _vm.isRental,
                        },
                      }),
                      _c("fleet-lock-unlock-button", {
                        attrs: { id: _vm.id, vin: _vm.vin },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          !_vm.isRental
            ? _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    [
                      _c(
                        "b-alert",
                        { attrs: { show: "", variant: "danger" } },
                        [
                          _c("strong", [_vm._v("ATENÇÃO!")]),
                          _vm._v(" Este é um veículo do Kovi Seguros "),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                {
                  staticClass: "text-right fleet-nav",
                  attrs: { md: "12", sm: "12" },
                },
                [
                  _c(
                    "b-nav",
                    { attrs: { tabs: "", align: "left" } },
                    [
                      _c("b-nav-item", { attrs: { to: `/fleet/${_vm.id}` } }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("fleetProfile.tabs.profile")) +
                            " "
                        ),
                      ]),
                      _vm.isRental
                        ? _c(
                            "b-nav-item",
                            { attrs: { to: `/fleet/${_vm.id}/bookings` } },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("fleetProfile.tabs.leases")) +
                                  " "
                              ),
                            ]
                          )
                        : _vm._e(),
                      _c(
                        "b-nav-item",
                        { attrs: { to: `/fleet/${_vm.id}/block-history` } },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("fleetProfile.tabs.blockHistory")) +
                              " "
                          ),
                        ]
                      ),
                      _vm.isRental
                        ? _c(
                            "b-nav-item",
                            {
                              attrs: {
                                to: `/fleet/${_vm.id}/fines${
                                  _vm.$route.query.redir
                                    ? "?redir=" + _vm.$route.query.redir
                                    : ""
                                }`,
                              },
                            },
                            [
                              _vm._v(" Multas "),
                              _c("b-badge", { attrs: { variant: "light" } }, [
                                _vm._v(
                                  " " + _vm._s(_vm.profile.total_fines) + " "
                                ),
                              ]),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "b-nav-item",
                        { attrs: { to: `/fleet/${_vm.id}/tracker-v2` } },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("fleetProfile.tabs.tracker")) +
                              " "
                          ),
                        ]
                      ),
                      _c(
                        "b-nav-item",
                        { attrs: { to: `/fleet/${_vm.id}/checklist` } },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("fleetProfile.tabs.checklist")) +
                              " "
                          ),
                        ]
                      ),
                      _c(
                        "b-nav-item",
                        { attrs: { to: `/fleet/${_vm.id}/history` } },
                        [_vm._v(" Histórico (Beta) ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }